/* eslint-disable @next/next/no-img-element */
import { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import { PopupButton } from "@typeform/embed-react";

export default function Desktop({ setOpen }) {
  let current = useRouter().pathname.split("/")[1];
  const [cookies, _] = useCookies(["ref"]);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (cookies.ref) setRef(cookies.ref);
  }, [cookies]);

  return (
    <header className="border-b border-gray-200 top-0 bg-white sticky z-[60]">
      <div className="hidden lg:flex flex-row gap-12 items-center justify-between max-w-7xl  mx-auto py-4  px-6 ">
        <Link href="/" className="flex flex-row gap-2 items-center">
          <img
            className="h-11 w-auto "
            src="/logo.png"
            alt="JackRabbit orange rabbit"
          />
          <p className="text-sm text-jackOrange font-header mt-2">
            Jackrabbit Ops
          </p>
        </Link>
        {/* <div className="flex flex-row gap-8 items-center">
          <div
            className={` ${
              current == "features" ? "text-[#F6800A]" : "text-gray-700"
            } hover:text-[#F6800A]`}
          >
            <Link href="/features">AI Manager Features</Link>
            {current == "features" && (
              <div className="translate-y-7  -mt-1 pb-1 w-full bg-gradient-to-r from-[#F6800A]/20 via-[#F6800A]/60  to-[#F6800A]/20" />
            )}
          </div>
          <div
            className={` ${
              current == "price" ? "text-[#F6800A]" : "text-gray-700"
            } hover:text-[#F6800A]`}
          >
            <Link href="/price">Pricing</Link>
            {current == "price" && (
              <div className="translate-y-7  -mt-1 pb-1 w-full bg-gradient-to-r from-[#F6800A]/20 via-[#F6800A]/60  to-[#F6800A]/20" />
            )}
          </div>
          <div
            className={` ${
              current == "blog" ? "text-[#F6800A]" : "text-gray-700"
            } hover:text-[#F6800A]`}
          >
            <Link href="/blog">Blog</Link>
            {current == "blog" && (
              <div className="translate-y-7  -mt-1 pb-1 w-full bg-gradient-to-r from-[#F6800A]/20 via-[#F6800A]/60  to-[#F6800A]/20" />
            )}
          </div>
          <div
            className={` ${
              current == "90-day-challenge" ? "text-[#F6800A]" : "text-gray-700"
            } hover:text-[#F6800A]`}
          >
            <Link href="/90-day-challenge">90 Day Challenge</Link>
            {current == "90-day-challenge" && (
              <div className="translate-y-7  -mt-1 pb-1 w-full bg-gradient-to-r from-[#F6800A]/20 via-[#F6800A]/60  to-[#F6800A]/20" />
            )}
          </div>
        </div> */}

        <div className="flex flex-row gap-8 items-center">
          {/* <div className="text-gray-700 hover:text-[#F6800A]">
            <a href="https://app.jackrabbitops.com/login">Login</a>
          </div> */}
          <div className="flex flex-row gap-8 items-center">
            <div
              className={` ${
                current == "features" ? "text-[#F6800A]" : "text-gray-700"
              } hover:text-[#F6800A]`}
            >
              <Link href="/#features">Features</Link>
              {current == "features" && (
                <div className="translate-y-7  -mt-1 pb-1 w-full bg-gradient-to-r from-[#F6800A]/20 via-[#F6800A]/60  to-[#F6800A]/20" />
              )}
            </div>
            <div
              className={` ${
                current == "price" ? "text-[#F6800A]" : "text-gray-700"
              } hover:text-[#F6800A]`}
            >
              <Link href="/#price">Pricing</Link>
              {current == "price" && (
                <div className="translate-y-7  -mt-1 pb-1 w-full bg-gradient-to-r from-[#F6800A]/20 via-[#F6800A]/60  to-[#F6800A]/20" />
              )}
            </div>
          </div>
          <div
            className={` ${
              current == "blog" ? "text-[#F6800A]" : "text-gray-700"
            } hover:text-[#F6800A]`}
          >
            <Link href="/blog">Blog</Link>
            {current == "blog" && (
              <div className="translate-y-7  -mt-1 pb-1 w-full bg-gradient-to-r from-[#F6800A]/20 via-[#F6800A]/60  to-[#F6800A]/20" />
            )}
          </div>
          <Link
            href="https://sdr.jackrabbitops.com/register"
            className="border border-gray-800 hover:text-gray-900 rounded-md px-6 py-2 bg-gray-900 text-white hover:bg-white"
          >
            Get Access - 2mins
          </Link>
        </div>
      </div>
    </header>
  );
}

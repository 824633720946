import { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { useCookies } from "react-cookie";
import { PopupButton } from "@typeform/embed-react";

export default function Example({ setOpen }) {
  const [cookies, _] = useCookies(["ref"]);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (cookies.ref) setRef(cookies.ref);
  }, [cookies]);

  return (
    <header className=" lg:hidden top-0 bg-black sticky z-[60] ">
      <div className="min-h-full ">
        <Popover as="header">
          {({ open }) => (
            <>
              <div className="flex flex-row justify-between lg:hidden p-4  bg-white border-b border-gray-300 items-center">
                {/* <Popover.Button className="inline-flex items-left justify-left rounded-md bg-transparent p-2 text-white over:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3BottomLeftIcon
                      className="h-9 w-9"
                      aria-hidden="true"
                    />
                  )}
                </Popover.Button> */}
                <Link href="/">
                  <img
                    className="h-10 w-auto mx-auto"
                    src="/logo.png"
                    alt="JackRabbit orange rabbit"
                  />
                </Link>
                <Link
                  href="https://sdr.jackrabbitops.com/register"
                  className="border border-gray-800 hover:text-gray-900 rounded-md px-6 py-2 bg-gray-900 text-white hover:bg-white"
                >
                  Get Access - 2mins
                </Link>
                {/* <div className="relative group w-max ml-auto">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                  <a
                    href={`https://app.jackrabbitops.com/register${
                      ref ? `?ref=${ref}` : ""
                    }`}
                  >
                    <button className="relative px-4 py-2 bg-white rounded-lg leading-none flex items-center divide-x divide-gray-600">
                      <span className="text-black group-hover:colorGradient transition duration-200 text-sm">
                        Try Now
                      </span>
                    </button>
                  </a>
                </div> */}
              </div>
              <Transition.Root as={Fragment}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="fixed inset-0 z-20 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition"
                    >
                      <div className="divide-y divide-gray-200 rounded-lg bg-black shadow-lg ring-1 ring-black ring-opacity-5 z-40">
                        <div className="pt-3 pb-2 z-40">
                          <div className="flex items-center justify-between px-4">
                            <Link href="/">
                              <img
                                className="h-14 w-auto "
                                src="/logo.png"
                                alt="JackRabbit orange rabbit"
                              />
                            </Link>
                            <div className="-mr-2">
                              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-600">
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-12 space-y-1 px-2">
                            <div className="flex-row gap-8 items-center ml-3 text-lg ">
                              <div className="text-gray-300 hover:text-black mb-4">
                                <Link href="/features">AI Manger Features</Link>
                              </div>
                              <div className="text-gray-300 hover:text-black mb-4">
                                <Link href="/price">Pricing</Link>
                              </div>
                              <div className="text-gray-300 hover:text-black mb-4">
                                <Link href="/blog">Blog</Link>
                              </div>
                              <div className="text-gray-300 hover:text-black mb-4">
                                <Link href="/90-day-challenge">
                                  90 Day Challenge
                                </Link>
                              </div>
                              {/* <div className="text-gray-300 hover:text-black mb-4">
                                <a href="https://app.jackrabbitops.com/login">
                                  Login
                                </a>
                              </div> */}
                              <div className="relative group mb-4 w-max">
                                <div className="absolute -inset-0.5 bg-gradient-to-r from-white to-gray-200 rounded-lg blur opacity-40 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                                <a
                                  href={`https://app.jackrabbitops.com/register${
                                    ref ? `?ref=${ref}` : ""
                                  }`}
                                >
                                  <button className="relative px-5 py-3 bg-black rounded-lg leading-none flex items-center divide-x divide-gray-600">
                                    <span className="text-gray-300 group-hover:text-gray-100 transition duration-200">
                                      Try Free &rarr;
                                    </span>
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
            </>
          )}
        </Popover>
      </div>
    </header>
  );
}

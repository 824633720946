// COMPONENTS
import DesktopNav from "../navigation/desktop";
import HamburgerNav from "../navigation/hamburger";
import Footer from "../footer";

export default function Base({ setOpen, children, blackOut, noFooter }) {
  const style = blackOut ? "bg-black" : "";
  return (
    <div className={style}>
      <DesktopNav setOpen={setOpen} />
      <HamburgerNav setOpen={setOpen} />
      {children}
      {!noFooter && <Footer />}
    </div>
  );
}
